/* eslint no-shadow: ["error", { "allow": ["state"] }] */

import isEmail from "validator/lib/isEmail";

import addresses from "../../api/addresses";
import users from "../../api/user";

const state = {
  shippingAddresses: [],
  billingAddresses: [],
  guest: false,
  guestType: null,
  loginErrors: {},
  registerErrors: {},
  cards: [],
  stripeV2Cards: [],
  checkoutDotComCards: [],
  credit: {},
  newCustomer: false,
  first_name: "",
  last_name: "",
  email: "",
  isInternal: false
};

const getters = {};

const actions = {
  async addShippingAddress({ commit, dispatch }, address) {
    commit("setLoading", true, { root: true });
    const response = await addresses.create(address);
    commit("setLoading", false, { root: true });
    if (response) {
      if (response.data.success) {
        commit("setShippingAddresses", response.data.shipping_addresses);
        commit("setGuest", response.data.user.guest);
        dispatch("cart/commitCartData", response.data.cart, { root: true });
        commit("totals/setTotals", response.data.cart.totals, { root: true });
      } else {
        commit("setFormErrors", response.data.errors, { root: true });
        throw new Error("Invalid address");
      }
    } else {
      throw new Error("Invalid address");
    }
  },
  async updateShippingAddress({ commit, dispatch }, { id, address }) {
    commit("setLoading", true, { root: true });
    const response = await addresses.updateFromCheckout(id, address);
    commit("setLoading", false, { root: true });
    if (response) {
      if (response.data.success) {
        commit("setFormErrors", [], { root: true });
        commit("setShippingAddresses", response.data.shipping_addresses);
        commit("setGuest", response.data.user.guest);
        dispatch("cart/commitCartData", response.data.cart, { root: true });
        commit("totals/setTotals", response.data.cart.totals, { root: true });
      } else {
        commit("setFormErrors", response.data.errors, { root: true });
        throw new Error("Invalid address");
      }
    } else {
      // handle error here
    }
  },
  async deleteShippingAddress({ commit, dispatch }, { id }) {
    commit("setLoading", true, { root: true });
    const response = await addresses.deleteFromCheckout(id);
    commit("setLoading", false, { root: true });
    if (response) {
      if (response.data.success) {
        commit("setShippingAddresses", response.data.shipping_addresses);
        commit("setGuest", response.data.user.guest);
        dispatch("cart/commitCartData", response.data.cart, { root: true });
        commit("totals/setTotals", response.data.cart.totals, { root: true });
      } else {
        commit("setFormErrors", response.data.errors, { root: true });
        throw new Error("Invalid address");
      }
    } else {
      // handle error here
    }
  },
  async checkGuestEmail({ commit }, email) {
    if (!email || !isEmail(email)) {
      commit("setLoginErrors", {
        email: ["This doesn't look like a valid email"]
      });
      return;
    }

    commit("setLoginErrors", {});
    commit("setLoading", true, { root: true });
    const response = await users.checkGuestEmail(email);
    commit("setLoading", false, { root: true });

    if (response) {
      commit("setGuestType", "existing");
      commit("setGuestCheckoutFormShowing", "login", { root: true });
      commit("setLoginErrors", {
        email: ["You have an account with us already!"]
      });
    } else {
      commit("setGuestType", "new");
    }
  },
  async login({ commit }, details) {
    if (!details.email || !isEmail(details.email)) {
      commit("setLoginErrors", {
        email: ["This doesn't look like a valid email"]
      });
      return;
    }

    commit("setLoginErrors", {});
    commit("setLoading", true, { root: true });
    const response = await users.login(details);
    if (response.success) {
      commit("setLoginErrors", {});
      analytics.track("Logged In", { situation: "Papier Website" });
      if (details.weddingAddOns) {
        const showWeddingAddOnsFromLogin = new CustomEvent(
          "showWeddingAddOnsFromLogin"
        );
        window.dispatchEvent(showWeddingAddOnsFromLogin);
        const closeLoginModal = new Event("closeLoginModal");
        window.dispatchEvent(closeLoginModal);
        commit("setLoading", false, { root: true });
      } else {
        window.location.reload();
      }
    } else {
      commit("setLoading", false, { root: true });
      commit("setLoginErrors", { email: [response.data.error] });
    }
  },
  async register({ commit }, details) {
    if (!details.email || !isEmail(details.email)) {
      commit("setRegisterErrors", {
        email: ["This doesn't look like a valid email"]
      });
      return;
    }

    commit("setRegisterErrors", {});
    commit("setLoading", true, { root: true });
    const response = await users.register(details);
    if (response.success) {
      commit("setRegisterErrors", {});
      analytics.track("Signed Up", {
        location: details.location,
        situation: "Papier Website"
      });
      if (details.weddingAddOns) {
        const showWeddingAddOnsFromLogin = new CustomEvent(
          "showWeddingAddOnsFromLogin"
        );
        window.dispatchEvent(showWeddingAddOnsFromLogin);
        const closeLoginModal = new Event("closeLoginModal");
        window.dispatchEvent(closeLoginModal);
        commit("setLoading", false, { root: true });
      } else {
        window.location.reload();
      }
    } else {
      commit("setLoading", false, { root: true });
      commit("setRegisterErrors", response.data.errors);
      commit("setGuestCheckoutFormShowing", "login", { root: true });
      // handle error here
    }
  }
};

const mutations = {
  setShippingAddresses(state, shippingAddress) {
    state.shippingAddresses = shippingAddress;
  },
  setGuest(state, flag) {
    state.guest = flag;
  },
  setGuestType(state, guestType) {
    state.guestType = guestType;
  },
  setLoginErrors(state, errors) {
    state.loginErrors = errors;
  },
  setNewCustomer(state, newCustomer) {
    state.newCustomer = newCustomer;
  },
  setRegisterErrors(state, errors) {
    state.registerErrors = errors;
  },
  setCards(state, cards) {
    state.cards = cards;
  },
  setCheckoutDotComCards(state, cards) {
    state.checkoutDotComCards = cards;
  },
  setStripeV2Cards(state, cards) {
    state.stripeV2Cards = cards;
  },
  setCredit(state, credit) {
    state.credit = credit;
  },
  setFirstName(state, name) {
    state.first_name = name;
  },
  setLastName(state, name) {
    state.last_name = name;
  },
  setEmail(state, email) {
    state.email = email;
  },
  setBillingAddresses(state, billingAddresses) {
    state.billingAddresses = billingAddresses;
  },
  setIsInternal(state, isInternal) {
    state.isInternal = isInternal;
  },
  removeCard(state, cardId) {
    state.stripeV2Cards = state.stripeV2Cards.filter(
      card => card.id !== cardId
    );
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
